import { chakra } from '@chakra-ui/react'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby' 

const GoogleMaps = (props) => {

	const { contentfulSitemetadata: { address } } = useStaticQuery(graphql`
		query {
			contentfulSitemetadata {
				address
			}
		}
	`)

	return(
		<chakra.div {...props}>
			<iframe
				width="100%"
				height="100%"
				src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDr-jKPYvN8BKnv1vOFyPiDOZLfT6VW14s&q=${address}`}
				allowFullScreen />
		</chakra.div>
	)

}

export default GoogleMaps