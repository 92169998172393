import { Box, Circle, Divider, Flex, Heading, HStack, Icon, Link, ListItem, SimpleGrid, Text, UnorderedList, VStack } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import { FaClock, FaEnvelope, FaFacebookSquare, FaInstagram, FaMapMarked, FaMapMarkerAlt, FaPhone, FaWhatsapp } from 'react-icons/fa'
import Container from '../components/container'
import GoogleMaps from '../components/google-maps'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { formatWhatsappNumber } from '../utils'

export const query = graphql`
	query {
		page: contentfulPage(slug: { eq: "contato"}) {
			...contactPageData
		}
		contact: contentfulSitemetadata {
			...allSiteInfo
		}
	}
`

const ContentfulTemplateContact = ({data, ...rest}) => {

	const { page, contact } = data

	return(

		<Layout>
			<SEO
				title={page.title}
				description={page.metadescription.metadescription}
			/>
			<Box py={32}>
				<Container>
					<Box pt={16} pb={8} textAlign="center">
						<Heading as="h1">{page.template.heading}</Heading>
						<Divider my={2} />
						<Text fontSize="xl" color="octopus.500">
							{page.template.description.description}
						</Text>
					</Box>
				</Container>
				<Container>
					<Flex flexDirection="column">
						<Box pb={4}>
							<SimpleGrid columns={{base: 1, md: 4}} spacing={4}>
									<Box backgroundColor="brand.700" borderRadius="md" p={4}>
										<Flex flexDirection="column" alignItems="center" textAlign="center" mt={-12}>
											<Circle boxSize={16} backgroundColor="brand.500" >
												<Icon color="white" boxSize={8} as={FaWhatsapp} />
											</Circle>
											<Heading color="white" py={2} fontSize="md" fontWeight="normal" as="h4">
												Chame-nos no Whatsapp
											</Heading>
											<Link href={`https://wa.me/55${formatWhatsappNumber(contact.whatsapp)}`} isExternal textDecoration="underline" fontSize="md" fontWeight="bold" color="white">
												{contact.whatsapp}
											</Link>
										</Flex>
									</Box>
								
								
									<Box backgroundColor="brand.700" borderRadius="md" p={4}>
										<Flex flexDirection="column" alignItems="center" textAlign="center" mt={-12}>
											<Circle boxSize={16} backgroundColor="brand.500" >
												<Icon color="white" boxSize={8} as={FaInstagram} />
											</Circle>
											<Heading color="white" py={2} fontSize="md" fontWeight="normal" as="h4">
												Siga-nos no Instagram
											</Heading>
											<Link href={`https://instagram.com/${contact.instagram}`} isExternal  textDecoration="underline" fontSize="md" fontWeight="bold" color="white">
												@{contact.instagram}
											</Link>
										</Flex>
									</Box>
								
								
									<Box backgroundColor="brand.700" borderRadius="md" p={4}>
										<Flex flexDirection="column" alignItems="center" textAlign="center" mt={-12}>
											<Circle boxSize={16} backgroundColor="brand.500" >
												<Icon color="white" boxSize={8} as={FaFacebookSquare} />
											</Circle>
											<Heading color="white" py={2} fontSize="md" fontWeight="normal" as="h4">
												Siga-nos no Facebook
											</Heading>
											<Link href={`https://facebook.com/${contact.facebook}`} isExternal  textDecoration="underline" fontSize="md" fontWeight="bold" color="white">
												@{contact.facebook}
											</Link>
										</Flex>
									</Box>
								
								
									<Box backgroundColor="brand.700" borderRadius="md" p={4}>
										<Flex flexDirection="column" alignItems="center" textAlign="center" mt={-12}>
											<Circle boxSize={16} backgroundColor="brand.500" >
												<Icon color="white" boxSize={8} as={FaEnvelope} />
											</Circle>
											<Heading color="white" py={2} fontSize="md" fontWeight="normal" as="h4">
												Envie-nos um Email
											</Heading>
											<Link href={`mailto:${contact.email}`} textDecoration="underline" fontSize="md" fontWeight="bold" color="white">
												{contact.email}
											</Link>
										</Flex>
									</Box>
								</SimpleGrid>
						</Box>
						<Box backgroundColor="brand.700" color="white" borderRadius="md">
							<Flex flexDirection={{base: "column", md: "row"}}>
								<GoogleMaps flex="1" />
								<Box flex="1"  p={{base: 8, md: 16}} >
									<VStack alignItems="start" spacing={8}>
										<Box>
											<HStack>
												<Icon boxSize={{base: 6, md: 8}} as={FaMapMarkerAlt}  />
												<Text fontSize={{base: "xl", md: "2xl"}} fontWeight="bold">Endereço</Text>
											</HStack>
											<Text pl={{base: 8, md: 10}} fontSize="lg">{contact.address}</Text>
										</Box>
										<Box>
											<HStack>
												<Icon boxSize={{base: 6, md: 8}} as={FaClock}  />
												<Text fontSize={{base: "xl", md: "2xl"}} fontWeight="bold">Horário de atendimento</Text>
											</HStack>
											<Text pl={{base: 8, md: 10}} fontSize="lg">{contact.openingTime}</Text>
										</Box>
										<Box>
											<HStack>
												<Icon boxSize={{base: 6, md: 8}} as={FaMapMarked}  />
												<Text fontSize={{base: "xl", md: "2xl"}} fontWeight="bold">Regiões de atuação</Text>
											</HStack>
											<UnorderedList pl={{base: 8, md: 10}} fontSize="lg">
												{contact.operatingAreas.map((area, index) => (
													<ListItem key={`area-${index}`}>
														{area}
													</ListItem>
												))}
											</UnorderedList>
										</Box>
										<Box>
											<HStack>
												<Icon boxSize={{base: 6, md: 8}} as={FaPhone}  />
												<Text fontSize={{base: "xl", md: "2xl"}} fontWeight="bold">Telefone</Text>
											</HStack>
											<Text pl={{base: 8, md: 10}} fontSize="lg">{contact.phone}</Text>
										</Box>
									</VStack>
								</Box>
							</Flex >
						</Box>
					</Flex>
				</Container>
			</Box>
		</Layout>

	)

}

export default ContentfulTemplateContact